import { Heading } from "@chakra-ui/react";
import React from "react";
import Container from "../components/Container";
import Quizes from "../containers/Quizes";
import Seo from "../containers/Seo";
import ogImage from "../images/ogImage.png";

const Render = (props) => {
  return (
    <>
      <Seo
        title="Ooopen lab 測驗範例"
        ogImage={ogImage}
        description="這是 RE:LAB 資訊設計顧問公司宇宙中的新品牌「超開放實驗室」，不定期自製能讓人在思考中變得更好的測驗。帶給你的受眾，全新的溝通體驗。把品牌想傳達的內容，用好玩的方式說。"
      />
      <Container maxWidth={768} py="8">
        <Heading textAlign="center">交換禮物</Heading>
        <Quizes my="4" />
      </Container>
    </>
  );
};

export default Render;
