import React from 'react'
import { Helmet } from 'react-helmet'
import gilooOg from './PickOneGame/giloo/Facebook分享圖 1200x630.jpg'
import justfontOg from './DraggerGame/justfont/fb-cover-w-logo.png'
import bonneOg from '../images/bonne-chance/上上芊 cover.png'
import plainlawOg from '../images/plainlaw/FB分享圖.png'


const Seo = ({
  title,
  ogImage,
  description,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="og:image" content={ogImage} />
      <meta
        name="description"
        content={description}
      />
    </Helmet>
  )
}

Seo.JustFont = () => (
  <Seo
    title="字體期末考"
    description="字體世界中有許多令人生畏的名詞、術語，與似是而非的謠傳⋯⋯你能辨認誰對誰錯嗎？"
    ogImage={justfontOg}
  />
)


Seo.Giloo = () => (
  <Seo
    title="你的靈魂裡住著哪位名人？"
    description="完成測驗就能獲得免費觀影序號！你會是藝術家、法師、搖滾明星或是大獨裁者？"
    ogImage={gilooOg}
  />
)

Seo.BonneChance = () => (
  <Seo
    title="營養認知大考驗－假新聞謠言麥擱騙"
    description="LINE上熱門的健康營養資訊是真是假？立即挑戰看看！"
    ogImage={bonneOg}
  />
)

Seo.Plainlaw = () => (
  <Seo
    title="不正常國家的不正常憲法：你真的懂中華民國憲法嗎？"
    description="本次的測驗中，法律白話文運動設計了11個與憲法有關的小問題，希望能透過這些問題來引導大家，去思考看看我們的憲法有些什麼問題。"
    ogImage={plainlawOg}
  />
)

export default Seo
