import { Box, Text, Image, LinkBox, LinkOverlay, Stack, Center } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import ReLink from '../components/Link'
import { responsive } from '../components/ThemeProvider/theme'
import partners from './partners'

const Quizes = ({ partner, ...props }) => {
  const sorted = useMemo(() => partner ? partners.sort((a) => a.id === partner ? 1 : -1).sort((a, b) => (b.isLive ?? 0) - (a.isLive ?? 0)) : partners, [partner])
  return (
    <Stack spacing={8} {...props}>
      {sorted.map(({ label, to, href, img, logo, isLive, id }, i) => (
        <Box p="4" key={i} textAlign="center" position={`relative`}>
          <LinkBox title={label}>
            <Box
              borderRadius={`1em`}
              overflow={`hidden`}
              border={`2px solid`}
              position="relative"
              transform={`translateZ(0)`}
            >
              {img ?? <Image src={`https://picsum.photos/seed/${label}/600/300`} />}
              {!isLive && (
                <Center position={`absolute`} left="0" right="0" bottom="0" top="0" bg="rgba(0,0,0,0.33)">
                  {/* <Text color="white">即將上線</Text> */}
                </Center>
              )}
              <Box
                position={`absolute`}
                bg={isLive ? 'red.500' : 'gray.700'}
                color={`white`}
                top="0"
                left="0"
                px="2.75em"
                py="0.25em"
                letterSpacing={`wider`}
                transform={`rotate(-30deg) translate(-25%, -40%)`}
                fontSize={responsive('sm', 'md')}
              >
                {isLive ? '立即體驗' : '即將上線'}
              </Box>
            </Box>
            <Box
              bg="white"
              border={`2px solid`}
              position={`absolute`}
              width="50%"
              maxWidth="300px"
              left="50%"
              bottom={`0`}
              transform={`translate(-50%, 50%)`}
              rounded={`full`}
              overflow={`hidden`}
              px="5%"
            >
              {logo ?? <Image src={`https://via.placeholder.com/300x100/FFFFFF/000000?text=LOGO`} />}
              {/* <Text mb="0.5em">{isLive ? '即刻體驗！' : '即將上線'}</Text> */}
            </Box>
            {/* <Box position="absolute" bottom="-3em" width={`full`} textAlign={`center`}>
              <Text fontSize={responsive('sm', 'md')}>
                {isLive ? '即刻體驗' : '即將上線'}
              </Text>
            </Box> */}
            <Text lineHeight={0} fontSize={1} color={`white`}>
              {isLive ? (
                <LinkOverlay
                  to={to}
                  href={href}
                  as={ReLink}
                  isExternal={!!href}
                  onClick={() => partner && window.gtag("event", "button-click", { name: `${partner}->${id}` })}
                >
                  {label}
                </LinkOverlay>
              ) : label}
            </Text>
          </LinkBox>
        </Box>
      ))}
    </Stack>
  )
}

export default Quizes
